import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DestinatarioService {

    private DESTINATARIOS_URL = 'api/dest-users';
    private SEARCH_USERNAME_URL = 'api/dest-users/search-username';
    private POST_USER_DEST_URL = 'api/dest-users/post-user';
    private DELETE_USER_DEST_URL = 'api/dest-users/delete-user';
    private UPDATE_USER_DEST_URL = 'api/dest-users/update-user';

    constructor(private http: HttpClient) { }

    public getDestinatarios(apenasAcompanhando: Boolean) {
        return this.http.get(`${this.DESTINATARIOS_URL}?apenasAcompanhando=${apenasAcompanhando}`)
    }

    public searchUsername(search) {
        return this.http.get(`${this.SEARCH_USERNAME_URL}?search=${search}`);
    }

    public deleteDestUser(id_user: number) {
        return this.http.delete(`${this.DELETE_USER_DEST_URL}/${id_user}`);
    }

    public postDestUser(sAMAccountName: string, displayName: string) {
        const user = {
            sAMAccountName,
            displayName
        };
        return this.http.post(`${this.POST_USER_DEST_URL}`, {user});
    }

    public updateUser(user) {
        return this.http.put(`${this.UPDATE_USER_DEST_URL}`, {user});
    }

}
