import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FornecedorService {
    private FORNECEDOR_BY_ID_URL: string = '/api/fornecedor'
    private RAMO_NEGOCIO_URL: string = '/api/ramo-negocio'
    private NATUREZA_JURIDICA_URL: string = '/api/natureza-juridica'
    private MUNICIPIO_URL: string = '/api/municipio'
    private PORTE_EMPRESA_URL: string = '/api/porte-empresa'
    private GET_EMPRESA_PORTAL_TRANSPARENCIA: string = '/api/empresa-portal-trans/id/'

    constructor(private http: HttpClient) { }

    public getFornecedorById(id){
        return this.http.get(`${this.FORNECEDOR_BY_ID_URL}/${id}`)
    }

    public getEmpresaPortalTransp(id){
        return this.http.get(`${this.GET_EMPRESA_PORTAL_TRANSPARENCIA}/${id}`)

    }

    public getRamoNegocio() {
        return this.http.get(`${this.RAMO_NEGOCIO_URL}`)
    }

    public getNaturezaJuridica() {
        return this.http.get(`${this.NATUREZA_JURIDICA_URL}`)
    }

    public getMunicipio() {
        return this.http.get(`${this.MUNICIPIO_URL}`)
    }

    public getPorteEmpresa() {
        return this.http.get(`${this.PORTE_EMPRESA_URL}`)
    }

}
