import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ProcessoTcuService {
    private MOVIMENTACOES_URL: string = '/api/movimentacoes'
    private PROCESSOS_URL: string = '/api/processos-tcu'
    private UNIDADES_URL: string = '/api/unidades'
    private PECAS_URL: string = '/api/pecas-processo'
    private DOWNLOAD_PECA_URL: string = '/api/download-peca-processo'
    private RELATORES_URL: string = '/api/relatores'
    private TIPOS_URL: string = '/api/tipos-processo-tcu'
    private ESTADOS_URL: string = '/api/estados-processo-tcu'

    constructor(private http: HttpClient) { }

    public getProcessosTCU(id_relator='', tipo='', estado='', processo='') {
        return this.http.get(`${this.PROCESSOS_URL}?id_relator=${id_relator}&tipo=${tipo}&estado=${estado}&processo=${processo}`)
    }

    public getMovimentacoes(id_processo) {
        return this.http.get(`${this.MOVIMENTACOES_URL}/${id_processo}`)
    }

    public getUnidades(id_processo) {
        return this.http.get(`${this.UNIDADES_URL}/${id_processo}`)
    }

    public getPecas(id_processo) {
        return this.http.get(`${this.PECAS_URL}/${id_processo}`)
    }

    public downloadPeca(id_peca): Observable<Blob> {
        return this.http.get(`${this.DOWNLOAD_PECA_URL}/${id_peca}`,  {responseType: 'blob'})
    }

    public getRelatores() {
        return this.http.get(`${this.RELATORES_URL}`)
    }

    public getEstados() {
        return this.http.get(`${this.ESTADOS_URL}`)
    }

    public getTipos() {
        return this.http.get(`${this.TIPOS_URL}`)
    }

}
