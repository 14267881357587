import { Component, OnInit, Input } from '@angular/core';
import { PregaoService } from '../services/pregao.service'

@Component({
    selector: 'app-mensagem-sessao-publica',
    templateUrl: './mensagem-sessao-publica.component.html',
    styleUrls: ['./mensagem-sessao-publica.component.css']
})
export class MensagemSessaoPublicaComponent implements OnInit {

    @Input() pregao
    esclarecimentos:any = []
    tipo: string = 'E'
    ordem: string = 'DESC'
    emissor: string = 'Todos'
    fields = [
        { label: 'Esclarecimentos', value: 'E'},
        { label: 'Avisos', value: 'A'},
        { label: 'Mensagens', value: 'M'}
    ]
    ordens = [
        { label: 'Mais Recentes', value: 'DESC'},
        { label: 'Mais Antigos', value: 'ASC'},
    ]
    emissores = [
        { label: 'Todos', value: 'Todos'},
        { label: 'Pregoeiro', value: 'Pregoeiro'},
        { label: 'Sistema', value: 'Sistema'},
        { label: 'Fornecedor', value: 'Fornecedor'},
    ]

    constructor(private pregaoService: PregaoService) { }

    onChangeDropdown(event) {
        this.getMensagensSessaoPublica()
    }

    getMensagensSessaoPublica() {
        this.pregaoService.getMensagensSessaoPublica(this.pregao.id_pregao, this.tipo, this.emissor, this.ordem).subscribe
        (resp =>{
            let resposta: any[] = []
            resposta = JSON.parse(JSON.stringify(resp)) 
            let field = 'data_hora';

    const groupedObj = resposta.reduce((prev, cur)=> {

      if(!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});

    this.esclarecimentos = Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
        })
    }

    ngOnInit(): void {
        this.getMensagensSessaoPublica()
    }

}
