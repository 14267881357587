export class PessoaJuridicaSocio {
    private _id_pessoa_juridica_socio: number;
    private _id_pessoa_juridica: number;
    private _id_fornecedor: number;
    private _cpf_cnpj: string;
    private _qualificacao: string;
    private _socio: string;

    constructor(objeto = null) {
        this._id_pessoa_juridica_socio = objeto._id_pessoa_juridica_socio
        this._id_pessoa_juridica = objeto.id_pessoa_juridica
        this._id_fornecedor = objeto.id_fornecedor
        this._cpf_cnpj = objeto.cpf_cnpj
        this._qualificacao = objeto.qualificacao
        this._socio = objeto.socio

    }

    get id_pessoa_juridica_socio() { return this._id_pessoa_juridica_socio }

    get id_pessoa_juridica() { return this._id_pessoa_juridica }

    get id_fornecedor() { return this._id_fornecedor }

    get cpf_cnpj() { return this._cpf_cnpj }

    get qualificacao() { return this._qualificacao }

    get socio() { return this._socio }

    set socio(newValue) { this._socio = newValue }

    set id_pessoa_juridica_socio(newValue){ this._id_pessoa_juridica_socio = newValue }

    set id_pessoa_juridica(newValue) { this._id_pessoa_juridica = newValue }

    set id_fornecedor(newValue) { this._id_fornecedor = newValue }

    set cpf_cnpj(newValue) { this._cpf_cnpj = newValue }

    set qualificacao(newValue) { this._qualificacao = newValue }

} 