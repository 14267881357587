import { Routes, RouterModule } from '@angular/router'
import { ModuleWithProviders } from '@angular/core'
import { ErrorStackComponent } from '@nuvem/primeng-components'
import { LoginSuccessComponent, AuthGuard } from '@nuvem/angular-base'
import { DocumentTableViewComponent } from './document-table-view/document-table-view.component'
import { DocumentViewComponent } from './document-view/document-view.component'
import { DocumentNavigatorComponent } from './document-navigator/document-navigator.component'
import { KeywordsViewComponent } from './keywords-view/keywords-view.component'
import { PesquisaRecursoComponent } from './pesquisa-recurso/pesquisa-recurso.component'
import { PesquisaMensagemComponent } from './pesquisa-mensagem/pesquisa-mensagem.component'
import { CadastroLicitacaoAcompanhamentoComponent } from './cadastro-licitacao-acompanhamento/cadastro-licitacao-acompanhamento.component'
import { ListagemPregaoComponent } from './listagem-pregao/listagem-pregao.component'
import { InicioComponent } from './inicio/inicio.component'
import { PregaoViewComponent } from './pregao-view/pregao-view.component'
import { ListagemFornecedoresComponent } from './listagem-fornecedores/listagem-fornecedores.component'
import { PesquisaAnexosLicitacoesComponent } from './pesquisa-anexos-licitacoes/pesquisa-anexos-licitacoes.component'
import { ProcessosTcuViewComponent } from './processos-tcu-view/processos-tcu-view.component'
import { UsuariosDestinoComponent } from './usuarios-destino/usuarios-destino.component'
import { CadastroDestinatarioComponent } from './cadastro-destinatario/cadastro-destinatario.component'
import { FornecedorViewComponent } from './fornecedor-view/fornecedor-view.component'

export const routes: Routes = [
    { path: '', component: InicioComponent },
    { path: 'diario-erros', component: ErrorStackComponent },
    { path: 'login-success', component: LoginSuccessComponent },
    { path: 'documentos', component: DocumentTableViewComponent, canActivate: [AuthGuard] },
    { path: 'pesquisa-textual', component: DocumentViewComponent, canActivate: [AuthGuard] },
    { path: 'documento/:uuid', component: DocumentNavigatorComponent, canActivate: [AuthGuard] },
    { path: 'pregao/:id', component: PregaoViewComponent, canActivate: [AuthGuard] },
    { path: 'palavras-chave', component: KeywordsViewComponent, canActivate: [AuthGuard] },
    { path: 'pesquisa-recurso', component: PesquisaRecursoComponent, canActivate: [AuthGuard] },
    { path: 'pesquisa-mensagem', component: PesquisaMensagemComponent, canActivate: [AuthGuard] },
    { path: 'pregoes', component: ListagemPregaoComponent, canActivate: [AuthGuard] },
    { path: 'pregoes/acompanhar', component: CadastroLicitacaoAcompanhamentoComponent, canActivate: [AuthGuard] },
    { path: 'fornecedores', component: ListagemFornecedoresComponent, canActivate: [AuthGuard] },
    { path: 'pesquisa-anexos', component: PesquisaAnexosLicitacoesComponent, canActivate: [AuthGuard] },
    { path: 'processos-tcu', component: ProcessosTcuViewComponent, canActivate: [AuthGuard] },
    { path: 'usuarios-destino', component: UsuariosDestinoComponent, canActivate: [AuthGuard]},
    { path: 'usuarios-destino/adicionar', component: CadastroDestinatarioComponent, canActivate: [AuthGuard] },
    { path: 'fornecedor/:id', component: FornecedorViewComponent, canActivate: [AuthGuard] },

]
export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes)