import { Component, OnInit } from '@angular/core';
import { KeywordService } from '../services/keyword.service'
import { BreadcrumbService } from '@nuvem/primeng-components';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'app-keywords-view',
    templateUrl: './keywords-view.component.html',
    styleUrls: ['./keywords-view.component.css'],
    styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .custom-toast .ui-toast-message {
            color: #ffffff;
            background: #FC466B;
            background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);
            background: linear-gradient(to right, #3F5EFB, #FC466B);
        }

        :host ::ng-deep .custom-toast .ui-toast-close-icon {
            color: #ffffff;
        }
    `],
    providers: [MessageService]
})
export class KeywordsViewComponent implements OnInit {

    keyword:string = ''
    keywords: Array<Object> = []
    entity:string  = 'nenhuma'
    entities = [
        { label: 'Nenhuma', value: 'nenhuma'},
        { label: 'Organização', value: 'organizacao'},
        { label: 'Jurisprudência', value: 'jurisprudencia'},
        { label: 'Legislação', value: 'legislacao'},
        { label: 'Local', value: 'local'},
        { label: 'Pessoa', value: 'pessoa'},
        { label: 'Tempo', value: 'tempo'}]

    constructor(private keywordService: KeywordService,
                private breadcrumbService: BreadcrumbService,
                private messageService: MessageService) { }

    addKeyword() {
        if (this.keyword.length > 0) {
            this.keywords.push({ entity: this.entity, word: this.keyword })
            this.keyword = ''
        }
    }

    getToastDetail() {
        return this.keywords.length > 0 ? 'Palavras-chave armazenadas.' : 'Palavras-chave excluídas.'
    }

    deleteKeywords() {
        this.keywords = []
    }

    showSuccess(detail) {
        this.messageService.clear()
        this.messageService.add({ severity:'success', summary: 'Sucesso!', detail: detail });
    }

    saveKeywords() {
        this.keywordService.saveKeyword(this.keywords).subscribe((keywords: Array<Object>) => this.keywords = keywords)
    }

    getKeywords() {
        this.keywordService.getKeywords().subscribe(keywords => this.keywords = keywords[0]._source.keywords)
    }

    ngOnInit() {
        this.getKeywords()
        this.breadcrumbService.setItems([{ label: 'Palavras-chave' }]);
    }

}
