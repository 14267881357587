import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { LicitacaoService } from '../services/licitacao.service';
import { BreadcrumbService } from '@nuvem/primeng-components';
import {interval, from, Subscription} from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'app-anexos-pregao',
    templateUrl: './anexos-pregao.component.html',
    styleUrls: ['./anexos-pregao.component.scss']
})
export class AnexoPregaoComponent implements OnInit, OnDestroy {

    fornecedor = '';
    conteudo = '';
    loading: Boolean = false;
    listaProcessos: any;
    uuidProcesso;
    showData: Boolean = false;
    progressSubscription: Subscription;

    @Input() pregao;

    anexos: any = [];

    constructor(private breadcrumbService: BreadcrumbService,
                private licitacaoService: LicitacaoService,
                private messageService: MessageService) { }

    ngOnInit() {
        this.getAnexos();
        setTimeout(() => {
            if (this.anexos.length > 0) {
                this.showData = true;
            }
        }, 1500);
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }

    getAnexos() {
        if (this.pregao) {
            this.loading = true;
            this.licitacaoService
                .getAnexos(this.pregao.codigo_uasg, this.pregao.numero_licitacao, this.fornecedor, this.conteudo)
                .subscribe(anexos => {
                    this.anexos = anexos;
                    this.loading = false;
                },
                err => this.loading = false);
        }
    }

    updateProcesso() {
        this.progressSubscription = interval(2000).pipe(
            switchMap(() => from(this.licitacaoService.getProcessoAnexo(this.uuidProcesso))),
            takeWhile((resp: any) => {
                let atualizar = true;
                if (resp.length > 0) {
                    const proc = resp.find(processo => processo.data_fim === null);
                    if (proc === undefined) {
                        atualizar = false;
                        this.listaProcessos = undefined;
                        this.showData = true;
                        this.getAnexos();
                    }
                }

                return atualizar;
            }),
        ).subscribe((resp: any[]) => {
            this.listaProcessos = resp;
        });
    }

    showToast(detail, severity, summary) {
        this.messageService.clear();
        this.messageService.add({ key: 'toast-coleta-anexos', severity: severity, summary: summary, detail: detail });
    }

    collectAnexosLicitacao() {
        this.licitacaoService
            .getAnexosLicitacao(this.pregao.id_pregao)
            .subscribe(resp => {
                this.showToast(resp['message'], resp['severity'], resp['summary']);
                if (resp['uuid'] !== undefined) {
                    this.uuidProcesso = resp['uuid'];
                    this.updateProcesso();
                }
            });
    }
}
