import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { BreadcrumbService, DatatableComponent } from '@nuvem/primeng-components';
import { PregaoService } from '../services/pregao.service';
import { DownloadXlsService } from '../services/download.xls.service';
import { DownloadPdfService } from '../services/download.pdf.service';
import { MessageService } from 'primeng/api';
import {interval, from, Subscription} from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';


@Component({
  selector: 'app-resultado-lance-pregao',
  templateUrl: './resultado-lance-pregao.component.html',
  styleUrls: ['./resultado-lance-pregao.component.scss']
})
export class ResultadoLancePregaoComponent implements OnInit, OnDestroy {

    @Input() pregao;

    grupos: any = [];
    itens: any = [];
    resultados: any = [];
    numeroItem: number;
    numeroGrupo: number;
    listaProcessos: any;
    uuidProcesso;
    progressSubscription: Subscription;

    constructor(private breadcrumbService: BreadcrumbService,
                private pregaoService: PregaoService,
                private messageService: MessageService,
                private downloadXlsService: DownloadXlsService,
                private downloadPdfService: DownloadPdfService) { }

    getResultados() {
        if (this.pregao) {
            this.pregaoService
                .getResultados(this.pregao.id_pregao, this.numeroItem, this.numeroGrupo)
                .subscribe(resp => {
                    this.resultados = resp;
            });
        }
    }

    updateProcesso() {
        this.progressSubscription = interval(2000).pipe(
            switchMap(() => from(this.pregaoService.getProcessoPregao(this.uuidProcesso))),
            takeWhile((resp: any[]) => {
                let atualizar = true;
                if (resp.length > 0) {
                    for (const processo of resp) {
                        if (processo.data_fim !== null) {
                            this.listaProcessos = resp;
                            if (processo.status === 'ERRO_COLETA') {
                                this.showToast('Ocorreu um problema na coleta dos resultadosa', 'error', 'Erro desconhecido');
                            } else {
                                this.getItens();
                                this.getGrupos();
                                setTimeout(() => {this.getResultados(); }, 1200);
                            }
                            atualizar = false;
                        }
                    }
                }

                return atualizar;
            }),
        ).subscribe((resp: any[]) => {
            this.listaProcessos = resp;
        });
    }

    collectResults() {
        this.pregaoService.collectResultadoLancePregao(this.pregao.id_pregao).subscribe(resp => {
            this.showToast(resp['message'], resp['severity'], resp['summary']);
            if (resp['uuid'] !== undefined) {
                this.uuidProcesso = resp['uuid'];
                this.updateProcesso();
            }
        });
    }

    onChangeDropdown(event) {
        this.getResultados();
    }

    showToast(detail, severity, summary) {
        this.messageService.clear();
        this.messageService.add({ key: 'toast-coleta-resultado-pregao', severity: severity, summary: summary, detail: detail });
    }

    getItens() {
        this.itens = [];
        this.pregaoService
            .getItensResultados(this.pregao.id_pregao)
            .subscribe((resp: any[]) => {
                this.itens = [];
                if (resp.length > 0) {
                    for (const item of resp) {
                        this.itens.push({label: 'Item ' + item.numero, value: item.numero});
                    }
                    if (this.numeroItem === undefined) {
                        this.numeroItem = this.itens[0].value;
                    }
                }
            });
    }

    getGrupos() {
        this.pregaoService
            .getGruposResultados(this.pregao.id_pregao)
            .subscribe((resp: any[]) => {
                this.grupos = [];
                if (resp.length > 0) {
                    for (const item of resp) {
                        this.grupos.push({label: 'Grupo ' + item.numero, value: item.numero});
                        if (this.grupos[0].value === 0) { break; }
                    }
                    if (this.numeroGrupo === undefined) {
                        this.numeroGrupo = this.grupos[0].value;
                    }
                }
            });
    }

    ngOnInit() {
        this.getItens();
        this.getGrupos();

        setTimeout(() => {this.getResultados(); }, 1200);
    }


    ngOnDestroy() {
        this.breadcrumbService.reset();
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }

    downloadXLS() {
        this.downloadXlsService.getXlsFile(this.pregao);
    }

    downloadPDF() {
        this.downloadPdfService.getPdfFile(this.pregao);
    }
}
