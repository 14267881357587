import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { Usuario } from './usuario/usuario';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {

    public usuario: Usuario;

    constructor(public app: AppComponent) {}

}
