import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { MensagemService } from '../services/mensagem.service';


@Component({
    selector: 'app-pesquisa-mensagem',
    templateUrl: './pesquisa-mensagem.component.html',
    styleUrls: ['./pesquisa-mensagem.component.css']
})
export class PesquisaMensagemComponent implements OnInit {

    codigoUASG
    numeroLicitacao
    dataInicio
    dataFim
    nomeFornecedor
    mensagem:string = ''
    trechoMensagem: string = ''
    loading: Boolean = false


    mensagens: any[] = []

    constructor(private breadcrumbService: BreadcrumbService,
                private mensagemService: MensagemService) { }

    ngOnInit() {
        this.breadcrumbService.setItems([{ label: 'Pesquisa Mensagens' }]);
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    highlight(text, uuid) {
        if (!this.trechoMensagem.length)
            return text

        let inputText = document.getElementById(uuid);
        let index     = text.indexOf(this.trechoMensagem);

        if (index >= 0) {
            text = `<b> ${text.substring(0,index)}
                <mark> ${text.substring(index,index+this.trechoMensagem.length)} </mark>
                ${text.substring(index + this.trechoMensagem.length)} </b>`

            inputText.innerHTML = text;
        }
    }

    formatDateTime(date) {
        return date !== undefined ? date.toLocaleString() : ''
    }

    getMensagens() {
        this.loading = true
        this.mensagemService
            .getMensagens(this.codigoUASG, this.numeroLicitacao, this.formatDateTime(this.dataInicio), this.formatDateTime(this.dataFim), this.nomeFornecedor, this.mensagem)
            .subscribe((resp: any[]) => {
                this.mensagens = resp
                let msg             = this.mensagem
                this.trechoMensagem = msg
                this.loading        = false
            },
            (err) => {
                this.loading = false
            })
    }

}
