export class Entity {
    private _type: string
    private _value: string
    private _sequence: number
    private _count: number
    private _quantity: number
    private _color: string
    private _element: any

    constructor(type = '', value = '', sequence = 0, count = 0, quantity = 0, element = null, color = '') {
        this._type = type
        this._value = value
        this._sequence = sequence
        this._count = count
        this._element = element
        this._quantity = quantity-1
        this._color = color
    }

    get element() {
        return this._element
    }

    get type() {
        return this._type
    }

    get value() {
        return this._value
    }

    get color() {
        return this._color
    }

    get sequence() {
        return this._sequence
    }

    get count() {
        return this._count
    }

    get quantity() {
        return this._quantity
    }

    set quantity(newValue) {
        this._quantity = newValue
    }

    set element(newValue) {
        this._element = newValue
    }

    set type(newValue) {
        this._type = newValue
    }

    set value(newValue) {
        this._value = newValue
    }
    
    set color(newValue) {
        this._color = newValue
    }

    set sequence(newValue) {
        this._sequence = newValue
    }

    set count(newValue) {
        this._count = newValue
    }

    getElementId() {
        return `${this._type}-${this._sequence}-${this._count}`
    }

    getPreviousEntityId() {
        return `${this._type}-${this._sequence - 1}-0`
    }

    getNextEntityId(next) {
        let seq = next ? this._sequence + 1 : this._sequence - 1
        return `${this._type}-${seq}-0`
    }

    getNextOccurenceId(count) {
        return `${this._type}-${this._sequence}-${count}`
    }
} 