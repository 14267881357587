export class NotaFiscal {
    private _id_nota_fiscal: number;
    private _id_pessoa_juridica: number;
    private _id_fornecedor: number;
    private _id_nota_portal_transparencia: number;
    private _numero: number;
    private _serie: number;
    private _valor_nota_fiscal: number
    private _chave_nota_fiscal: string;
    private _cnpj_fornecedor: string;
    private _cnpj_orgao_destinatario: string;
    private _data_emissao: string;
    private _data_tipo_evento_mais_recente: string;
    private _municipio_fornecedor: string;
    private _nome_fornecedor: string;
    private _orgao_destinatario: string;
    private _orgao_superior_destinatario: string;
    private _tipo_evento_mais_recente: string;
    private _uf_fornecedor: string;
    
    

    constructor(objeto = null) {
        this._id_nota_fiscal = objeto.id_nota_fiscal;
        this._id_pessoa_juridica = objeto.id_pessoa_juridica;
        this._id_fornecedor = objeto.id_fornecedor;
        this._id_nota_portal_transparencia = objeto.id_nota_portal_transparencia;
        this._numero = objeto.numero;
        this._serie = objeto.serie;
        this._valor_nota_fiscal = objeto.valor_nota_fiscal;
        this._chave_nota_fiscal = objeto.chave_nota_fiscal;
        this._cnpj_fornecedor = objeto.cnpj_fornecedor;
        this._cnpj_orgao_destinatario = objeto.cnpj_orgao_destinatario;
        this._data_emissao = objeto.data_emissao;
        this._data_tipo_evento_mais_recente = objeto.data_tipo_evento_mais_recente;
        this._municipio_fornecedor = objeto.municipio_fornecedor;
        this._nome_fornecedor = objeto.nome_fornecedor;
        this._orgao_destinatario = objeto.orgao_destinatario;
        this._orgao_superior_destinatario = objeto.orgao_superior_destinatario;
        this._tipo_evento_mais_recente = objeto.tipo_evento_mais_recente;
        this._uf_fornecedor = objeto.uf_fornecedor        
    }

    get id_nota_fiscal() { return this._id_nota_fiscal }

    get id_pessoa_juridica() { return this._id_pessoa_juridica }

    get id_fornecedor() { return this._id_fornecedor }

    get id_nota_portal_transparencia() { return this._id_nota_portal_transparencia }

    get numero() { return this._numero }

    get serie() { return this._serie }

    get valor_nota_fiscal() { return this._valor_nota_fiscal }

    get chave_nota_fiscal() { return this._chave_nota_fiscal }

    get cnpj_fornecedor() { return this._cnpj_fornecedor }

    get cnpj_orgao_destinatario() { return this._cnpj_orgao_destinatario }

    get data_emissao() { return this._data_emissao }

    get data_tipo_evento_mais_recente() { return this._data_tipo_evento_mais_recente }

    get municipio_fornecedor() { return this._municipio_fornecedor }

    get nome_fornecedor() { return this._nome_fornecedor }

    get orgao_destinatario() { return this._orgao_destinatario }

    get orgao_superior_destinatario() { return this._orgao_superior_destinatario }

    get tipo_evento_mais_recente() { return this._tipo_evento_mais_recente }

    get uf_fornecedor() { return this._uf_fornecedor }

    set id_nota_fiscal(newValue) { this._id_nota_fiscal = newValue }

    set id_pessoa_juridica(newValue) { this._id_pessoa_juridica = newValue }

    set id_fornecedor(newValue) { this._id_fornecedor = newValue }

    set id_nota_portal_transparencia(newValue) { this._id_nota_portal_transparencia = newValue }

    set numero(newValue) { this._numero = newValue }

    set serie(newValue) { this._serie = newValue }

    set valor_nota_fiscal(newValue) { this._valor_nota_fiscal = newValue }

    set chave_nota_fiscal(newValue) { this._chave_nota_fiscal = newValue }

    set cnpj_fornecedor(newValue) { this._cnpj_fornecedor = newValue }

    set cnpj_orgao_destinatario(newValue) { this._cnpj_orgao_destinatario = newValue }

    set data_emissao(newValue) { this._data_emissao = newValue }

    set data_tipo_evento_mais_recente(newValue) { this._data_tipo_evento_mais_recente = newValue }

    set municipio_fornecedor(newValue) { this._municipio_fornecedor = newValue }

    set nome_fornecedor(newValue) { this._nome_fornecedor = newValue }

    set orgao_destinatario(newValue) { this._orgao_destinatario = newValue }

    set orgao_superior_destinatario(newValue) { this._orgao_superior_destinatario = newValue }

    set tipo_evento_mais_recente(newValue) { this._tipo_evento_mais_recente = newValue }

    set uf_fornecedor(newValue) { this._uf_fornecedor = newValue }


} 