import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableButtonComponent } from '@nuvem/primeng-components';
import { FornecedorService } from '../services/fornecedor.service';

@Component({
  selector: 'app-listagem-fornecedores',
  templateUrl: './listagem-fornecedores.component.html',
  styleUrls: ['./listagem-fornecedores.component.css']
})
export class ListagemFornecedoresComponent implements OnInit {

  fornecedores: any = []
  cpf_cnpj: string = ''
  nome: string = ''
  loading: Boolean = false
  natureza_juridica: any = []
  ramo_negocio: any = []
  municipio: any = []
  sigla_uf: string = ''
  porte_empresa: any = []
  limite: number = 20
  pagina: number = 1
  query: any =  {'nome':'','cpf_cnpj':'','sigla_uf':''};

  constructor(private fornecedorService: FornecedorService, private router: Router) { }

  ngOnInit() {
  }

  click(event) {
    if (event.button === 'edit') {
      console.log('editar')
    }
    if (event.button == 'view') {
      this.router.navigate(['/fornecedor/'+event.selection.id_fornecedor])
    }
    if (event.button === 'delete') {
      console.log('deletar')
    }
  }

}

