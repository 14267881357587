import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PregaoService } from '../services/pregao.service'
import { BreadcrumbService } from '@nuvem/primeng-components';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-pregao-view',
    templateUrl: './pregao-view.component.html',
    styleUrls: ['./pregao-view.component.css']
})
export class PregaoViewComponent implements OnInit {
    
    hidden: boolean = false
    editar: boolean = false
    @Input() pregao
    acompanhar

    constructor(private route: ActivatedRoute, 
        private pregaoService: PregaoService, 
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.breadcrumbService.setItems([{ label: 'Pregões', routerLink: '/pregoes' }]);
        let params = this.route.snapshot.paramMap.get('id').split('/')
        let id = params[0]

        this.pregaoService.getPregaoByID(id).subscribe(resp => {
            this.pregao = resp
            let acomp = resp['acompanhar']
            this.acompanhar = acomp
        })
    }

    showSuccess(detail) {
        this.messageService.clear()
        this.messageService.add({key: 'toast-acompanhamento-licitacao', severity:'success', summary: 'Sucesso!', detail: detail });
    }

    onEnterPress(i:boolean): void {
        this.onSubmit(i)
        this.isEditing(i)
    }

    isEditing(i:boolean): void {
        this.hidden=i
    }

    onSubmit(i: boolean): void {
        this.hidden=i
        this.pregaoService.updatePregao(this.pregao).subscribe((response) => {
            this.showSuccess(response)})
    }
}
