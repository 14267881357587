import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { BreadcrumbService, DatatableComponent } from '@nuvem/primeng-components';
import { ProcessoTcuService } from '../services/processo-tcu.service';
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-processos-tcu-view',
    templateUrl: './processos-tcu-view.component.html',
    styleUrls: ['./processos-tcu-view.component.css']
})
export class ProcessosTcuViewComponent implements OnInit {

    processos: any = []
    unidades: any = []
    pecas: any = []
    movimentacoes: any = []
    relatores: any = []
    estados: any = []
    tipos: any = []
    loading: boolean = false
    dialogMovimentacoes: boolean = false
    dialogUnidades: boolean = false
    dialogPecas: boolean = false
    relator
    estado
    tipo
    numeroProcesso

    constructor(private breadcrumbService: BreadcrumbService, private processoService: ProcessoTcuService) { }

    ngOnInit() {
        this.breadcrumbService.setItems([{ label: 'Processos TCU' }]);
        this.getProcessos()
        this.getRelatores()
        this.getTipos()
        this.getEstados()
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    pesquisar() {
        this.loading = true
        this.processoService.getProcessosTCU(this.relator, this.tipo, this.estado, this.numeroProcesso).subscribe(
            response => {
                this.processos = response
                this.loading = false
            },
            err => this.loading = false
        )
    }

    getProcessos() {
        this.loading = true
        this.processoService.getProcessosTCU().subscribe(
            response => {
                this.processos = response
                this.loading = false
            },
            err => this.loading = false
        )
    }

    abrirMovimentacoes(id_processo) {
        this.getMovimentacoes(id_processo)
        this.dialogMovimentacoes = true
    }

    getMovimentacoes(id_processo) {
        this.movimentacoes = []
        this.loading = true
        this.processoService.getMovimentacoes(id_processo).subscribe(
            response => {
                this.movimentacoes = response
                this.loading = false
            },
            err => this.loading = false
        )
    }

    abrirUnidades(id_processo) {
        this.getUnidades(id_processo)
        this.dialogUnidades = true
    }

    getUnidades(id_processo) {
        this.unidades = []
        this.loading = true
        this.processoService.getUnidades(id_processo).subscribe(
            response => {
                this.unidades = response
                this.loading = false
            },
            err => this.loading = false
        )
    }

    abrirPecas(id_processo) {
        this.getPecas(id_processo)
        this.dialogPecas = true
    }

    getPecas(id_processo) {
        this.pecas = []
        this.loading = true
        this.processoService.getPecas(id_processo).subscribe(
            response => {
                this.pecas = response
                this.loading = false
            },
            err => this.loading = false
        )
    }

    getRelatores() {
        this.loading = true
        this.processoService.getRelatores().subscribe((resp: any[]) => {
            this.relatores = resp.map(relator => {
                let dic = { label: relator.nome, value: relator.id_relator }
                return dic
            })
            this.loading = false
            },
            err => this.loading = false
        )
    }

    getTipos() {
        this.loading = true
        this.processoService.getTipos().subscribe((resp: any[]) => {
            this.tipos = resp.map(tipo => {
                let dic = { label: tipo.tipo, value: tipo.tipo }
                return dic
            })
            this.loading = false
            },
            err => this.loading = false
        )
    }

    getEstados() {
        this.loading = true
        this.processoService.getEstados().subscribe((resp: any[]) => {
            this.estados = resp.map(estado => {
                let dic = { label: estado.estado, value: estado.estado }
                return dic
            })
            this.loading = false
            },
            err => this.loading = false
        )
    }

    downloadPeca(id_peca, titulo) {
        this.processoService.downloadPeca(id_peca).subscribe(data => saveAs(data, `${titulo}.pdf`))
    }

    searchOnEnter($event: KeyboardEvent) {
        //TODO
    }
}
