import { Component, OnInit, Input } from '@angular/core';
import { PregaoService } from '../services/pregao.service'
import { LanceService } from '../services/lance.service';

@Component({
    selector: 'app-proposta-pregao',
    templateUrl: './proposta-pregao.component.html',
    styleUrls: ['./proposta-pregao.component.css']
})
export class PropostaPregaoComponent implements OnInit {

    propostas: any = []
    numeroItem: number
    numeroGrupo: number

    itens: any[] = []
    grupos: any[] = []
    @Input() idPregao
    dialog: boolean = false
    dialogProposta: any

    constructor(private pregaoService: PregaoService, private lanceService: LanceService) { }

    getPropostas() {
        this.pregaoService.getPropostas(this.numeroGrupo, this.numeroItem, this.idPregao).subscribe(resp => {
            this.propostas = resp
            this.getItens()
            this.getGrupos()
        })
    }

    getItens() {
        this.lanceService
            .getItens(this.idPregao)
            .subscribe((resp: any[]) => {
                this.itens = []
                if (resp.length > 0) {
                    for (let item of resp)
                        this.itens.push({label: 'Item ' + item.numero, value: item.numero})
                    if (this.numeroItem === undefined)
                        this.numeroItem = this.itens[0].value
                }  
            })
    }

    getGrupos() {
        this.lanceService
            .getGrupos(this.idPregao)
            .subscribe((resp: any[]) => {
                this.grupos = []
                if (resp.length > 0) {
                    for (let item of resp) {
                        this.grupos.push({label: 'Grupo ' + item.numero, value: item.numero})
                        if (this.grupos[0].value === 0) break
                    }
                    if (this.numeroGrupo === undefined)
                        this.numeroGrupo = this.grupos[0].value
                }
            })
    }

    onChangeItem(event) {
        this.getPropostas()
    }

    click(event) {
        if (event.button == 'view') {
            this.dialog = true
            this.dialogProposta = event.selection
        }
    }

    ngOnInit(): void {
        this.getItens()
        this.getGrupos()

        setTimeout(() => {this.getPropostas()}, 1000)

    }

}
