import { Component, OnInit, Input } from '@angular/core';
import { LicitacaoService } from '../services/licitacao.service'
import { PregaoService } from '../services/pregao.service';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'app-pesquisa-anexos-licitacoes',
    templateUrl: './pesquisa-anexos-licitacoes.component.html',
    styleUrls: ['./pesquisa-anexos-licitacoes.component.css']
})
export class PesquisaAnexosLicitacoesComponent implements OnInit {

    fornecedor: string = ''
    conteudo: string = ''
    loading: Boolean = false
    anexos: any = []
    pregoesAnexo:any = []
    filteredPregoes
    filtroPregoes: string = undefined
    pregoesOriginal


    constructor(private breadcrumbService: BreadcrumbService,
        private licitacaoService: LicitacaoService,
        private pregaoService: PregaoService,
        private messageService: MessageService) { }

    ngOnInit() {
        this.getAnexos()
        this.getPregoesComAnexo()
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    getPregoesComAnexo() {
        this.pregaoService.getpregoesAnexo().subscribe((resp: any[]) => {
            this.pregoesOriginal = resp
            this.pregoesAnexo = resp.map(pregao => `${pregao.apelido} - ${pregao.codigo_uasg} - ${pregao.numero_licitacao}`)
        })
    }

    getAnexos() {
        this.loading = true
        let uasg = this.filtroPregoes !== undefined ? this.pregoesOriginal.filter(pregao => this.filtroPregoes.includes(pregao.apelido))[0]['codigo_uasg'] : 0
        let nro = this.filtroPregoes !== undefined ? this.pregoesOriginal.filter(pregao => this.filtroPregoes.includes(pregao.apelido))[0]['numero_licitacao'] : 0
        this.licitacaoService
            .getAnexos(uasg, nro, this.fornecedor, this.conteudo)
            .subscribe(anexos => {
                this.anexos = anexos
                this.loading = false
            },
            err => this.loading = false)
    }

    showToast(detail, severity, summary) {
        this.messageService.clear()
        this.messageService.add({ key: 'toast-coleta-anexos', severity: severity, summary: summary, detail: detail });
    }

    search(event) {
        this.pregoesAnexo = this.pregoesAnexo.filter(pregao => pregao.includes(event.query))
    }

    filterPregoesAnexo(event) {
        this.filteredPregoes = this.filterPregoes(event.query, this.pregoesOriginal)
    }

    filterPregoes(query, pregoesAnexo) {
        let filtered: any[] = [];

        for (let pregao of pregoesAnexo)
            if (pregao.apelido.toLowerCase().indexOf(query.toLowerCase()) == 0 || 
                pregao.numero_licitacao.toLowerCase().indexOf(query.toLowerCase()) == 0 ||
                pregao.codigo_uasg.toLowerCase().indexOf(query.toLowerCase()) == 0)
                filtered.push(`${pregao.apelido} - ${pregao.codigo_uasg} - ${pregao.numero_licitacao}`);

        return filtered;
    }

}
