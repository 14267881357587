import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MensagemLicitacaoService {

    private HISTORICO_MENSAGEM_URL = '/api/post-pregao';

    constructor(private http: HttpClient) { }

    public getHistoricoMensagens(codigoUASG: number, numeroLicitacao: number, tipoPregao: string,
                                 apelido: string, id_sistema: number): Observable<{message, id_pregao}> {
        const pregao = {
            codigoUASG,
            numeroLicitacao,
            tipoPregao,
            apelido,
            id_sistema
        };
        return this.http.post<{message, id_pregao}>(`${this.HISTORICO_MENSAGEM_URL}`, {pregao});
    }
}
