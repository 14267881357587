import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { MensagemLicitacaoService } from '../services/mensagem-licitacao.service'
import { MessageService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { SistemaService } from '../services/sistema.service';
import {finalize} from 'rxjs/operators';
@Component({
    selector: 'app-cadastro-licitacao-acompanhamento',
    templateUrl: './cadastro-licitacao-acompanhamento.component.html',
    styleUrls: ['./cadastro-licitacao-acompanhamento.component.css']
})
export class CadastroLicitacaoAcompanhamentoComponent implements OnInit {

    selectedRadioValue = 'E';
    licitacaoForm = new FormGroup({});
    sistemas: SelectItem[] = [];
    TOAST_WAIT_TIME = 800;
    bloqueado = false;

    constructor(private formBuilder: FormBuilder,
        private breadcrumbService: BreadcrumbService,
        private mensagemService: MensagemLicitacaoService,
        private messageService: MessageService,
        private sistemaService: SistemaService,
        private router: Router) { }

    registrarAcompanhamento() {
        this.bloqueado = true;
        this.mensagemService
            .getHistoricoMensagens(this.codigoUASG.value, this.numeroLicitacao.value,
                this.selectedRadioValue, this.apelido.value, this.idSistema.value)
            .pipe(finalize(() => this.bloqueado = false))
            .subscribe(resp => {
                this.showMessage(resp.message, 'success', 'Sucesso');
                setTimeout(() => {
                    this.router.navigate(['/pregoes']);
                }, this.TOAST_WAIT_TIME);
            }, resp => this.showMessage(resp.error.message, 'error', 'Erro'));
    }

    showMessage(detail: string, severity: string, summary: string): void {
        this.messageService.clear();
        this.messageService.add({key: 'toast-acompanhamento-licitacao', detail, severity, summary });
    }

    ngOnInit() {
        this.breadcrumbService.setItems([
            {label: 'Pregões', routerLink: '/pregoes'},
            {label: 'Cadastro Licitação para Acompanhamento'}]);
        this.getSistemas();
        this.licitacaoForm = this.formBuilder.group({
            'codigoUASG': new FormControl(this.codigoUASG, [
                Validators.required,
                Validators.pattern(/^-?(0|[0-9]\d*)?$/)
            ]),
            'numeroLicitacao': new FormControl(this.numeroLicitacao, [
                Validators.required,
                Validators.pattern(/^-?(0|[0-9]\d*)?$/)
            ]),
            'apelido': new FormControl(this.apelido, [Validators.required]),
            'tipoPregao': new FormControl('E', this.tipoPregao),
            'idSistema': new FormControl(this.idSistema, [
                Validators.required,
            ])
        });
    }

    getSistemas() {
        this.sistemaService.getSistemas().subscribe(
            response => {
                for(var i in response){
                    this.sistemas.push({
                        label: `${response[i].nome_sistema}`,
                        value: `${response[i].id_sistema}`
                    })
                }
            }
        )
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    get apelido() { return this.licitacaoForm.get('apelido') }

    get codigoUASG() { return this.licitacaoForm.get('codigoUASG') }

    get numeroLicitacao() { return this.licitacaoForm.get('numeroLicitacao') }

    get tipoPregao() { return this.licitacaoForm.get('tipoPregao') }

    get idSistema() { return this.licitacaoForm.get('idSistema') }
}
