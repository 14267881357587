import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div class="card clearfix">
                <app-version-tag cssClass="footer-text-left"></app-version-tag>
                <span class="footer-text-left">BASIS Tecnologia</span>
                <span class="footer-text-right">
                  <span class="ui-icon ui-icon-copyright"></span>
                  <span>&copy; 2020-2024 Todos direitos reservados</span>
                </span>
            </div>
        </div>
    `
})
export class AppFooterComponent {

}
