export class PessoaJuridicaValores {
    private _id_pessoa_juridica_valores: number;
    private _id_pessoa_juridica: number;
    private _id_fornecedor: number;

    private _valor_bens_patrimoniais: string;
    private _valor_materiais: string;
    private _valor_obras: string;
    private _valor_outros: string;
    private _valor_servicos: string;
    

    constructor(objeto = null) {
        this._id_pessoa_juridica_valores = objeto.id_pessoa_juridica_valores
        this._id_pessoa_juridica = objeto.id_pessoa_juridica
        this._id_fornecedor = objeto.id_fornecedor
        this._valor_bens_patrimoniais = objeto.valor_bens_patrimoniais
        this._valor_materiais = objeto.valor_materiais
        this._valor_obras = objeto.valor_obras
        this._valor_outros = objeto.valor_outros
        this._valor_servicos = objeto.valor_servicos
    }

    get id_pessoa_juridica_valores() { return this._id_pessoa_juridica_valores }

    get id_pessoa_juridica() { return this._id_pessoa_juridica }

    get id_fornecedor() { return this._id_fornecedor }

    get valor_bens_patrimoniais() { return this._valor_bens_patrimoniais }

    get valor_materiais() { return this._valor_materiais }

    get valor_obras() { return this._valor_obras }

    get valor_outros() { return this._valor_outros }

    get valor_servicos() { return this._valor_servicos }

    set id_pessoa_juridica_valores(newValue) { this._id_pessoa_juridica_valores = newValue }

    set id_pessoa_juridica(newValue) { this._id_pessoa_juridica = newValue }

    set id_fornecedor(newValue) { this._id_fornecedor = newValue }

    set valor_bens_patrimoniais(newValue) { this._valor_bens_patrimoniais = newValue }

    set valor_materiais(newValue) { this._valor_materiais = newValue }

    set valor_obras(newValue) { this._valor_obras = newValue }

    set valor_servicos(newValue) { this.valor_servicos = newValue }

    set valor_outros(newValue) { this.valor_outros = newValue }

} 