import { PRIMENG_IMPORTS } from './shared/primeng-imports';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import { InplaceModule } from 'primeng/inplace';
import { AppComponent } from './app.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { SharedModule } from './shared/shared.module';
import { PipeModule } from '@nuvem/angular-base';

import { CacheInterceptor } from './shared/cache-interceptor';
import { AuthInterceptor } from './shared/auth-interceptor';

import {
    AccessbilityModule,
    VersionTagModule,
    ClipboardModule,
    ErrorModule,
    SecurityModule
} from '@nuvem/angular-base';

import {
    PageNotificationModule,
    BlockUiModule,
    DatatableModule,
    ErrorStackModule,
    BreadcrumbModule,
    MenuModule
} from '@nuvem/primeng-components';
import { UploadDocumentoComponent } from './upload-documento/upload-documento.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { DocumentNavigatorComponent } from './document-navigator/document-navigator.component';
import { DocumentTableViewComponent } from './document-table-view/document-table-view.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { KeywordsViewComponent } from './keywords-view/keywords-view.component';
import { AnexoPregaoComponent } from './anexos-pregao/anexos-pregao.component';
import { PesquisaRecursoComponent } from './pesquisa-recurso/pesquisa-recurso.component';
import { LancePregaoComponent } from './lance-pregao/lance-pregao.component';

import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { PesquisaMensagemComponent } from './pesquisa-mensagem/pesquisa-mensagem.component';
import { CadastroLicitacaoAcompanhamentoComponent } from './cadastro-licitacao-acompanhamento/cadastro-licitacao-acompanhamento.component';
import { ListagemPregaoComponent } from './listagem-pregao/listagem-pregao.component';
import { environment } from 'src/environments/environment';
import { InicioComponent } from './inicio/inicio.component';
import { ResultadoLancePregaoComponent } from './resultado-lance-pregao/resultado-lance-pregao.component';
import { PregaoViewComponent } from './pregao-view/pregao-view.component';
import { PropostaPregaoComponent } from './proposta-pregao/proposta-pregao.component';
import { MensagemSessaoPublicaComponent } from './mensagem-sessao-publica/mensagem-sessao-publica.component';
import { ListagemFornecedoresComponent } from './listagem-fornecedores/listagem-fornecedores.component';
import { PesquisaAnexosLicitacoesComponent } from './pesquisa-anexos-licitacoes/pesquisa-anexos-licitacoes.component';
import { ProcessosTcuViewComponent } from './processos-tcu-view/processos-tcu-view.component';
import { UsuariosDestinoComponent } from './usuarios-destino/usuarios-destino.component';
import { CadastroDestinatarioComponent } from './cadastro-destinatario/cadastro-destinatario.component';
import { FornecedorViewComponent } from './fornecedor-view/fornecedor-view.component';

registerLocaleData(localePt, 'pt');

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        PRIMENG_IMPORTS,
        AccessbilityModule,
        VersionTagModule,
        SharedModule,
        BlockUiModule,
        DatatableModule,
        PageNotificationModule,
        ErrorStackModule,
        ClipboardModule,
        ErrorModule,
        PipeModule,
        BreadcrumbModule,
        MenuModule,
        SecurityModule.forRoot(environment.auth),
        InplaceModule
    ],
    declarations: [
        AppComponent,
        AppFooterComponent,
        AppTopbarComponent,
        UploadDocumentoComponent,
        SpinnerOverlayComponent,
        DocumentNavigatorComponent,
        DocumentTableViewComponent,
        DocumentViewComponent,
        KeywordsViewComponent,
        AnexoPregaoComponent,
        PesquisaRecursoComponent,
        LancePregaoComponent,
        PesquisaMensagemComponent,
        CadastroLicitacaoAcompanhamentoComponent,
        ListagemPregaoComponent,
        InicioComponent,
        ResultadoLancePregaoComponent,
        PregaoViewComponent,
        PropostaPregaoComponent,
        MensagemSessaoPublicaComponent,
        ListagemFornecedoresComponent,
        PesquisaAnexosLicitacoesComponent,
        ProcessosTcuViewComponent,
        UsuariosDestinoComponent,
        CadastroDestinatarioComponent,
        FornecedorViewComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt'
        },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
