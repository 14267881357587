import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

@Component({
    selector: 'app-upload-documento',
    templateUrl: './upload-documento.component.html',
    styleUrls: ['./upload-documento.component.css'],
    animations: [
        trigger(
          'inOutAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ height: 0, opacity: 0 }),
                animate('0.3s ease-out', 
                        style({ height: 200, opacity: 1 }))
              ]
            ),
            transition(
              ':leave', 
              [
                style({ height: 200, opacity: 1 }),
                animate('0.3s ease-in', 
                        style({ height: 0, opacity: 0 }))
              ]
            )
          ]
        )
      ]
})
export class UploadDocumentoComponent implements OnInit {
    @Output() result = new EventEmitter()

    uploadedFile: any
    loading: boolean = false
    showUploadDocumento: boolean = true

    constructor() { }

    onUpload(event: any): void {
        this.uploadedFile = event.files[0]

        let resp = JSON.parse(event.xhr.response)

        this.result.emit(resp)

        this.loading = false

        this.showUploadDocumento = false
    }

    onBeforeUpload(event: any): void {
        this.loading = true
    }

    ngOnInit() {
    }

}
