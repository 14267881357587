import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../services/document.service'
import { BreadcrumbService } from '@nuvem/primeng-components';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent implements OnInit {

    documents
    searchArgument: string = ''
    display: boolean = false
    output
    showDocuments: boolean = false
    loading: Boolean = false

    constructor(private documentService: DocumentService, private breadcrumbService: BreadcrumbService) { }

    ngOnInit() {
        this.documentService.searchInDocuments(this.searchArgument).subscribe(docs => this.documents = docs)
        this.breadcrumbService.setItems([{ label: 'Pesquisa Textual' }]);
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    searchOnEnter(event) {
        if (event.keyCode == 13)
            this.searchDocuments()
    }

    removeSpaces(string: string) {
        let str = string.replace(' ', '')
        return str
    }

    searchDocuments() {
        if (this.searchArgument.length > 1) {
            this.documents = null
            this.loading   = true

            this.documentService
                .searchInDocuments(this.searchArgument)
                .subscribe(
                    docs => {
                        this.documents     = docs
                        this.showDocuments = true
                        this.loading       = false
                    },
                    err => this.loading = false,
                )
        }
    }

    fillText() {
        setTimeout(() => {
            if (this.searchArgument.length > 0) {
                for (let doc of this.documents) {
                    let count = 0
                    for (let occurence of doc['highlight']['content']) {
                        let element = document.getElementById(`${doc._id}-${count}`);
                        count++
                        element.innerHTML = occurence
                    }
                }
            }
        }, 100);
    }

}
