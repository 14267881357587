export class PessoaJuridicaAcordos {
    private _id_pessoa_juridica_acordos: number;
    private _id_pessoa_juridica: number;
    private _id_fornecedor: number;

    private _notas_fiscais_emitidas_valor: string;
    private _recursos_recebidos_valor: string;
    
    private _convenios_quantidade_acordos_encerrados : number;
    private _convenios_valor_acordos_encerrados: string;
    private _convenios_quantidade_acordos_vigentes: number;
    private _convenios_valor_acordos_vigentes: string;
    

    constructor(objeto = null) {
        this._id_pessoa_juridica_acordos = objeto.id_pessoa_juridica_acordos
        this._id_pessoa_juridica = objeto.id_pessoa_juridica
        this._id_fornecedor = objeto.id_fornecedor

        this._notas_fiscais_emitidas_valor = objeto.notas_fiscais_emitidas_valor
        this._recursos_recebidos_valor = objeto.recursos_recebidos_valor

        this._convenios_quantidade_acordos_encerrados = objeto.convenios_quantidade_acordos_encerrados
        this._convenios_valor_acordos_encerrados = objeto.convenios_valor_acordos_encerrados
        this._convenios_quantidade_acordos_vigentes = objeto.convenios_quantidade_acordos_vigentes
        this._convenios_valor_acordos_vigentes = objeto.convenios_valor_acordos_vigentes
    }

    get id_pessoa_juridica_acordos() { return this._id_pessoa_juridica_acordos }

    get id_pessoa_juridica() { return this._id_pessoa_juridica }

    get id_fornecedor() { return this._id_fornecedor }

    get notas_fiscais_emitidas_valor() { return this._notas_fiscais_emitidas_valor }

    get recursos_recebidos_valor() { return this._recursos_recebidos_valor }

    get convenios_quantidade_acordos_encerrados() { return this._convenios_quantidade_acordos_encerrados }

    get convenios_valor_acordos_encerrados() { return this._convenios_valor_acordos_encerrados }

    get convenios_quantidade_acordos_vigentes() { return this._convenios_quantidade_acordos_vigentes }

    get convenios_valor_acordos_vigentes() { return this._convenios_valor_acordos_vigentes }

    set id_pessoa_juridica_acordos(newValue) { this._id_pessoa_juridica_acordos = newValue }

    set id_pessoa_juridica(newValue) { this._id_pessoa_juridica = newValue }

    set id_fornecedor(newValue) { this._id_fornecedor = newValue }
    
    set notas_fiscais_emitidas_valor(newValue) { this._notas_fiscais_emitidas_valor = newValue }

    set recursos_recebidos_valor(newValue) { this._recursos_recebidos_valor = newValue }

    set convenios_quantidade_acordos_encerrados(newValue) { this._convenios_quantidade_acordos_encerrados = newValue }

    set convenios_valor_acordos_encerrados(newValue) { this._convenios_valor_acordos_encerrados = newValue }

    set convenios_quantidade_acordos_vigentes(newValue) { this._convenios_quantidade_acordos_vigentes = newValue }

    set convenios_valor_acordos_vigentes(newValue) { this._convenios_valor_acordos_vigentes = newValue }

} 