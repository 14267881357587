import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { LanceService } from '../services/lance.service';
import { MessageService } from 'primeng/api';
import {interval, from, Subscription} from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { PregaoService } from '../services/pregao.service';


@Component({
    selector: 'app-lance-pregao',
    templateUrl: './lance-pregao.component.html',
    styleUrls: ['./lance-pregao.component.scss']
})
export class LancePregaoComponent implements OnInit, OnDestroy {

    nomeFornecedor = '';
    numeroItem: number;
    numeroGrupo: number;
    lances: any = [];
    dataInicio = undefined;
    dataFim = undefined;
    loading: Boolean = false;

    itens: any[] = [];
    grupos: any[] = [];
    fornecedores;
    filteredFornecedores;
    listaProcessos: any;
    uuidProcesso;
    @Input() pregao;
    private progressSubscription: Subscription;

    constructor(private breadcrumbService: BreadcrumbService,
        private lanceService: LanceService,
        private messageService: MessageService,
        private pregaoService: PregaoService) { }


    formatDateTime(date) {
        return date !== undefined && date !== null ? date.toLocaleString() : '';
    }

    updateProcesso() {
        this.progressSubscription = interval(2000).pipe(
            switchMap(() => from(this.pregaoService.getProcessoPregao(this.uuidProcesso))),
            takeWhile((resp: any[]) => {
                let atualizar = true;
                if (resp.length > 0) {
                    for (const processo of resp) {
                        if (processo.data_fim !== null) {
                            this.listaProcessos = resp;
                            atualizar = false;
                            if (processo.status === 'NAO_ENCONTRADO') {
                                this.showToast('Os lances não foram encontrados na página do ComprasNet', 'info', 'Aviso');
                            } else {
                                this.getLances();
                            }
                        }
                    }
                }

                return atualizar;
            }),
        ).subscribe((resp: any[]) => {
            this.listaProcessos = resp;
        });
    }

    collectLances() {
        this.lanceService
            .buscarLances(this.pregao.id_pregao)
            .subscribe(resp => {
                this.showToast(resp['message'], resp['severity'], resp['summary']);
                if (resp['uuid'] !== undefined) {
                    this.uuidProcesso = resp['uuid'];
                    this.updateProcesso();
                }
            });
    }

    showToast(detail, severity, summary) {
        this.messageService.clear();
        this.messageService.add({ key: 'toast-coleta-ata-pregao', severity: severity, summary: summary, detail: detail });
    }

    getLances() {
        if (this.pregao) {
            this.loading = true;
            this.lanceService
                .getLances(this.pregao.id_pregao, this.numeroItem, this.numeroGrupo, this.nomeFornecedor.toUpperCase(),
                    this.formatDateTime(this.dataInicio), this.formatDateTime(this.dataFim))
                .subscribe(resp => {
                    this.lances = resp;
                    this.getItens();
                    this.getGrupos();
                    this.loading = false;
                },
                    (err) => {
                        this.loading = false;
                    });
            this.getFornecedoresPregao();
        }
    }

    getFornecedoresPregao() {
        if (this.pregao) {
            this.loading = true;
            this.lanceService
                .getFornecedoresPregao(this.pregao.id_pregao, this.numeroItem, this.numeroGrupo, this.formatDateTime(this.dataInicio), this.formatDateTime(this.dataFim))
                .subscribe((resp: any[]) => {
                    this.fornecedores = resp.map(fornecedor => fornecedor.nome);
                    this.loading = false;
                },
                    (err) => {
                        this.loading = false;
                    });
        }
    }

    getItens() {
        if (this.pregao) {
            this.loading = true;
            this.lanceService
                .getItens(this.pregao.id_pregao)
                .subscribe((resp: any[]) => {
                    this.itens = [];
                    if (resp.length > 0) {
                        for (const item of resp) {
                            this.itens.push({label: 'Item ' + item.numero, value: item.numero});
                        }
                        if (this.numeroItem === undefined) {
                            this.numeroItem = this.itens[0].value;
                        }
                    }
                    this.loading = false;
                },
                    (err) => {
                        this.loading = false;
                    });
        }
    }

    getGrupos() {
        if (this.pregao) {
            this.loading = true;
            this.lanceService
                .getGrupos(this.pregao.id_pregao)
                .subscribe((resp: any[]) => {
                    this.grupos = [];
                    if (resp.length > 0) {
                        for (const item of resp) {
                            this.grupos.push({label: 'Grupo ' + item.numero, value: item.numero});
                            if (this.grupos[0].value === 0) { break; }
                        }
                        if (this.numeroGrupo === undefined) {
                            this.numeroGrupo = this.grupos[0].value;
                        }
                    }
                    this.loading = false;
                },
                    (err) => {
                        this.loading = false;
                    });
        }
    }

    ngOnInit() {
        this.getItens();
        this.getGrupos();

        setTimeout(() => {this.getLances();}, 1000);

    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }

    search(event) {
        this.fornecedores = this.fornecedores.filter(fornecedor => fornecedor.includes(event.query));
    }

    filterFornecedores(event) {
        this.filteredFornecedores = this.filterFornecedor(event.query, this.fornecedores);
    }

    filterFornecedor(query, fornecedores) {
        const filtered: any[] = [];

        for (const fornecedor of fornecedores) {
            if (fornecedor.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(fornecedor);
            }
        }

        return filtered;
    }

}
