import { Component, OnInit, HostListener, Input } from '@angular/core';
import { SlideInOutVertical, SlideInOutHorizontal } from '../shared/animations'
import { Entity } from '../shared/entity.model'
import { DocumentService } from '../services/document.service'
import { BreadcrumbService } from '@nuvem/primeng-components';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { PreviousRouteService } from '../services/previous-route.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-document-navigator',
    templateUrl: './document-navigator.component.html',
    styleUrls: ['./document-navigator.component.css'],
    animations: [
        SlideInOutVertical,
        SlideInOutHorizontal
    ]
})
export class DocumentNavigatorComponent implements OnInit {

    entities: Object
    previousSelectedElement: Entity = new Entity()
    currentSelectedElement: Entity = new Entity()
    previousColor: string = ''
    innerWidth: any
    document: Object = null
    loading: Boolean = false
    uuid
    bucket_name

    constructor(private documentService: DocumentService, private breadcrumbService: BreadcrumbService, private route: ActivatedRoute, private router: Router, private previousRouteService: PreviousRouteService, private messageService: MessageService) { }

    uploadDocumentDoccano() {
        this.loading = true
        this.documentService.uploadDocumentDoccano(this.uuid, this.bucket_name).subscribe(resp => {
            this.loading = false
            this.showMessage(resp['message'], resp['severity'], resp['summary'])
            this.documentService.getDoccanoConfig().subscribe(resp => {
                window.open(resp['url'], '_blank')
            })
        },
        (err) => {
            this.loading = false
            console.log(err)
        })
    }
    
    showMessage(detail, severity, summary) {
        this.messageService.clear()
        this.messageService.add({key: 'toast-doc-nav', severity: severity, summary: summary, detail: detail });
    }

    scrollToOnSidenavClick(id: string, item: string): void {
        let previousElement = this.previousSelectedElement.element

        if (previousElement)
            this.resetElementStyle(previousElement)

        let elementSelected = document.getElementById(id)

        this.currentSelectedElement = new Entity(id.split('-')[0],
            this.capitalizeWord(item['valor']),
            item['sequencia'],
            0,
            item['quantidade'],
            elementSelected,
            elementSelected.style.color)
        this.previousColor = this.currentSelectedElement.color

        this.highlightElement(this.currentSelectedElement.element)

        let parentDiv = document.getElementById('labeled-text')
        this.scroll(parentDiv, this.currentSelectedElement.element.offsetTop - (parentDiv.offsetTop + 100), 600)

        let prev = this.currentSelectedElement
        this.previousSelectedElement = prev
    }

    goToEntity(isNext: boolean): void {
        // pessoa-0-2 -> pessoa-1-0
        let newEntity = this.getNextEntity(this.currentSelectedElement.sequence,
                                           this.currentSelectedElement.type,
                                           isNext)
        let newElement: HTMLElement
        let seq: number

        if (!newEntity) return;

        newElement = document.getElementById(this.currentSelectedElement.getNextEntityId(isNext))

        seq = isNext ? this.currentSelectedElement.sequence + 1 : this.currentSelectedElement.sequence - 1

        this.currentSelectedElement = new Entity(this.currentSelectedElement.type,
            this.capitalizeWord(newEntity['valor']),
            newEntity['sequencia'],
            0,
            newEntity['quantidade'],
            newElement,
            newElement.style.color)

        let previousElement = this.previousSelectedElement.element

        this.handleNavigation(previousElement, newElement)
    }

    navigateInEntity(isNext: boolean): void {
        // tag id - ex: pessoa-0-0
        let count = isNext
            ? this.currentSelectedElement.count + 1 <= this.currentSelectedElement.quantity
                ? this.currentSelectedElement.count + 1
                : this.currentSelectedElement.count
            : this.currentSelectedElement.count - 1 > 0
                ? this.currentSelectedElement.count - 1
                : 0

        let newElement = document.getElementById(this.currentSelectedElement.getNextOccurenceId(count))

        this.currentSelectedElement = new Entity(this.currentSelectedElement.type,
            this.currentSelectedElement.value,
            this.currentSelectedElement.sequence,
            count,
            this.currentSelectedElement.quantity + 1,
            newElement,
            newElement.style.color)

        let previousElement = this.previousSelectedElement.element

        this.handleNavigation(previousElement, newElement)
    }

    handleNavigation(previousElement, newElement) {
        if (newElement && previousElement) {
            this.resetElementStyle(previousElement)
            this.highlightElement(newElement)

            let parentDiv = document.getElementById('labeled-text')
            this.scroll(parentDiv, newElement.offsetTop - (parentDiv.offsetTop + 100), 600)

            this.previousSelectedElement = this.currentSelectedElement
        }
    }

    resetElementStyle(element: HTMLElement): void {
        element.style.backgroundColor = '#E6F2FF'
        element.style.color = this.previousColor
        element.style.padding = '0'
        element.style.borderRadius = '0'
    }

    highlightElement(element: HTMLElement): void {
        element.style.backgroundColor = 'orange'
        element.style.color = 'black'
        element.style.padding = '0.15em 0.3em'
        element.style.borderRadius = '7px'
    }

    easeInOutQuad(time: number, begin: number, change: number, duration: number): number {
        time /= duration / 2

        if (time < 1) return change / 2 * time * time + begin

        time--

        return -change / 2 * (time * (time - 2) - 1) + begin
    }

    capitalizeWord(word: string): string {
        return word[0].toUpperCase() + word.slice(1)
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.innerWidth = window.innerWidth;
    }

    scroll(element: HTMLElement, to: number, duration: number): void {
        let start = element.scrollTop, change = to - start, currentTime = 0, increment = 20


        let animateScroll = () => {
            currentTime += increment

            let val = this.easeInOutQuad(currentTime, start, change, duration)

            element.scrollTop = val

            if (currentTime < duration)
                setTimeout(animateScroll, increment)
        }
        animateScroll()
    }

    getNextEntity(seq, type, isNext) {
        for (let entity of this.entities[type]) {
            if ((isNext && entity['sequencia'] === seq + 1) || (!isNext && entity['sequencia'] === seq - 1))
                return entity
        }
        return null
    }

    getPreviousRoute(url) {
        switch(url) {
            case '/pesquisa-textual':
                return { label: 'Pesquisa Textual', routerLink: url }
            default:
                return { label: 'Documentos', routerLink: '/documentos' }
        }
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

    ngOnInit() {
        this.loading = true

        window.scrollTo({ top: 0 })

        let params = this.route.snapshot.paramMap.get('uuid').split('/')
        this.uuid = params[0].replace(/\s/g, '')
        this.bucket_name = params[1]
        let previousItem = this.getPreviousRoute(this.previousRouteService.getPreviousUrl())

        this.innerWidth = window.innerWidth;

        this.documentService.getDocumentOutput(this.uuid, this.bucket_name).subscribe(data => {
            this.document = data
            this.breadcrumbService.setItems([ previousItem, { label: this.document['name'] }]);
            this.previousColor = ''
            let dict = {}

            for (let entity of this.document['entities'])
                dict[entity['tipo']] = []

            for (let entity of this.document['entities'])
                dict[entity['tipo']].push({
                    'valor': entity['valor'],
                    'sequencia': entity['sequencia'],
                    'quantidade': entity['quantidade']
                })

            this.entities = dict

            let textDiv = document.getElementById('labeled-text');

            textDiv.style.display = 'block'
            textDiv.innerHTML = this.document['html'];

            this.loading = false
            this.scroll(textDiv, 0, 1000)
        },
        err => { 
            this.loading = false
            this.router.navigate(['/pesquisa-textual'])
        })
    }
}
