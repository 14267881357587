import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService, User } from '@nuvem/angular-base';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private login;

    constructor(login: AuthenticationService<User>) {
        this.login = login;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error && error.status === 401) {
                    // Apagar o cookie de sessão do Flask por conta de um erro na validação com Keycloak/CAS
                    document.cookie = "session=;-1"
                    this.login.redirect();
                } else {
                    return throwError(error);
                }
            })
        );
    }
}
