import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { PregaoService } from '../services/pregao.service'
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MensagemService } from '../services/mensagem.service';


@Component({
    selector: 'app-listagem-pregao',
    templateUrl: './listagem-pregao.component.html',
    styleUrls: ['./listagem-pregao.component.css']
})
export class ListagemPregaoComponent implements OnInit {

    deletar_mensagem: boolean = false
    id_pregao: number
    pregoes: any = []
    editDialog: boolean = false
    validateDialog: boolean = false
    pregaoEdicao: any = null
    novoApelido: string = ''
    novoUASG: string = ''
    novoNumeroLicitacao: string = ''
    acompanhar: boolean
    loading: Boolean = false
    apenasAcompanhados: Boolean = true
    search = {
        codigoUASG: '',
        numeroLicitacao: '',
        apelido: ''
    }
    FILTER_RELOAD_TIME: number = 500

    constructor(private mensagemService: MensagemService, private breadcrumbService: BreadcrumbService, private pregaoService: PregaoService, private messageService: MessageService, private router: Router) { }

    handleChangeAcompanhamento(event) {
        if (this.search.numeroLicitacao.length > 0 || this.search.codigoUASG.length > 0 || this.search.apelido.length > 0) {
            this.searchPregoes()
        } else {
            this.getPregoes()
        }
    }

    getPregoes() {
        this.loading = true
        this.pregaoService.getPregoes(this.apenasAcompanhados).subscribe(resp => {
            this.pregoes = resp
            this.loading = false
        },
            err => this.loading = false)
    }

    searchPregoes() {
        this.pregaoService.searchPregoes(this.search.codigoUASG, this.search.numeroLicitacao, this.search.apelido,this.apenasAcompanhados).subscribe(resp => {
            this.pregoes = resp
            this.loading = false
        },
            err => this.loading = false)
    }

    updatePregao() {
        if (this.pregaoEdicao !== null) {
            let pregao = this.pregaoEdicao
            pregao.apelido = this.novoApelido
            pregao.acompanhar = this.acompanhar
            pregao.numero_licitacao = this.novoNumeroLicitacao
            pregao.codigo_uasg = this.novoUASG

            this.pregaoService
                .updatePregao(pregao)
                .subscribe((resp:any) => {
                    this.novoApelido = ''
                    this.editDialog = false
                    this.getPregoes()
                    this.showToast('success', resp.message)
                }, err => {
                    this.showToast('error', err.error)
                    this.getPregoes()
                    this.editDialog = false
                })
        }
    }

    handleChange(event, pregao) {
        let message;
        this.pregaoService
            .updatePregao(pregao)
            .subscribe(resp => {
                this.getPregoes()
                message = resp;
                this.showToast('success', message.message)
            }, err => {
                this.showToast('error', err.statusText)
            })
    }

    showToast(severity, message) {
        this.messageService.add({ severity, summary: message })
    }

    editarPregao(event) {
        this.editDialog = true
        let pregao = event.selection
        this.pregaoEdicao = pregao
        this.novoApelido = this.pregaoEdicao.apelido
        this.acompanhar = this.pregaoEdicao.acompanhar
        this.novoNumeroLicitacao = this.pregaoEdicao.numero_licitacao
        this.novoUASG = this.pregaoEdicao.codigo_uasg
    }

    deletarPregao(deletar_mensagem = false) {
        console.log(`deletar ${deletar_mensagem}`)
        if (deletar_mensagem == true) {
            this.mensagemService.deleteMensagens(this.id_pregao).subscribe(response => {
                console.log(`resposta ${response}`)
            })
        }
        this.pregaoService
            .deletePregao(this.id_pregao)
            .subscribe((resp:any) => {
                this.getPregoes()
                this.showToast('success', resp.message)
                this.validateDialog = false
            }, err => {
                this.showToast('error', err)
                this.validateDialog = true
            })
    }

    click(event) {
        this.id_pregao = event.selection.id_pregao
        if (event.button === 'edit') {
            this.editarPregao(event)
        }

        if (event.button == 'view') {
            this.router.navigate(['/pregao', event.selection.id_pregao])
        }

        if (event.button === 'delete') {
            this.deletarPregao()
        }
    }

    dialogOff() {
        this.validateDialog = false
    }

    ngOnInit() {
        this.getPregoes()
        this.breadcrumbService.setItems([{ label: 'Pregões' }]);
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

}

