import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PregaoService {

    private UPDATE_PREGAO_URL = '/api/update-pregao';
    private LISTAGEM_PREGAO_URL = '/api/pregoes';
    private PREGAO_URL = '/api/pregao';
    private PESQUISA_PREGAO_URL = '/api/pesquisa-pregao';
    private PROCESSO_PREGAO_URL = '/api/processo/pregao';
    private RESULTADO_LANCE_PREGAO_URL = 'api/resultado-lance-pregao';
    private MENSAGEM_SESSAO_PUBLICA_URL = '/api/mensagem-sessao-publica';
    private COLLECT_RESULTADO_LANCE_PREGAO_URL = 'api/coleta-resultados';
    private ITENS_RESULTADO_LANCE_PREGAO_URL = 'api/itens-resultado-lance-pregao';
    private GRUPOS_RESULTADO_LANCE_PREGAO_URL = 'api/grupos-resultado-lance-pregao';
    private PROPOSTAS_URL = '/api/propostas';
    private PREGOES_ANEXO_URL = '/api/pregoes-com-anexo';
    private DELETE_PREGAO_URL = '/api/delete-pregao';

    constructor(private http: HttpClient) { }

    public getpregoesAnexo() {
        return this.http.get(`${this.PREGOES_ANEXO_URL}`);
    }

    public getMensagensSessaoPublica(idPregao, tipo, emissor, ordem) {
        return this.http.get(`${this.MENSAGEM_SESSAO_PUBLICA_URL}/${idPregao}/${tipo}/${emissor}/${ordem}`);
    }

    public getPropostas(numeroGrupo= 0, numeroItem= 1, idPregao) {
        return this.http.get(`${this.PROPOSTAS_URL}/${idPregao}/${numeroGrupo}/${numeroItem}`);
    }

    public collectResultadoLancePregao(idPregao: number) {
        return this.http.get(`${this.COLLECT_RESULTADO_LANCE_PREGAO_URL}?idPregao=${idPregao}`);
    }

    public getResultados(idPregao, numeroItem = 1, numeroGrupo = 0) {
        return this.http.get(`${this.RESULTADO_LANCE_PREGAO_URL}/${idPregao}/${numeroItem}/${numeroGrupo}`);
    }

    public getItensResultados(idPregao) {
        return this.http.get(`${this.ITENS_RESULTADO_LANCE_PREGAO_URL}/${idPregao}`);
    }

    public getGruposResultados(idPregao) {
        return this.http.get(`${this.GRUPOS_RESULTADO_LANCE_PREGAO_URL}/${idPregao}`);
    }

    public updatePregao(pregao) {
        return this.http.put(`${this.UPDATE_PREGAO_URL}`, {pregao});
    }

    public getPregoes(apenasAcompanhados: Boolean) {
        return this.http.get(`${this.LISTAGEM_PREGAO_URL}?apenasAcompanhados=${apenasAcompanhados}`);
    }

    public searchPregoes(codigoUASG, numeroLicitacao, apelido, apenasAcompanhados: Boolean) {
        if (codigoUASG.length < 1 && numeroLicitacao.length < 1 && apelido.length < 1) {
            return this.getPregoes(true);
        }
        else {
            return this.http.get(`${this.PESQUISA_PREGAO_URL}?codigo_uasg=${codigoUASG}&numero_licitacao=${numeroLicitacao}&apelido=${apelido}&apenasAcompanhados=${apenasAcompanhados}`);
        }
    }

    public deletePregao(id_pregao) {
        return this.http.delete(`${this.DELETE_PREGAO_URL}/${id_pregao}`);
    }

    public getPregaoByID(id_pregao) {
        return this.http.get(`${this.PREGAO_URL}/${id_pregao}`);
    }

    public getProcessoPregao(uuid) {
        return this.http.get(`${this.PROCESSO_PREGAO_URL}/${uuid}`);
    }

}

