import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class KeywordService {

    private KEYWORDS_URL: string = '/api/keywords'

    constructor(private http: HttpClient) { }

    public getKeywords() {
        return this.http.get(this.KEYWORDS_URL)
    }

    public saveKeyword(keywords: Array<Object>) {
        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
        }
        return this.http.post(this.KEYWORDS_URL, { keywords: keywords }, httpOptions)
    }
}
