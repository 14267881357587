export class Endereco {
    private _id_endereco: number;
    private _bairro_distrito: string;
    private _cep: string;
    private _complemento: string;
    private _logradouro: string;
    private _municipio: string;
    private _numero: string;
    private _uf: string;

    constructor(objeto = null) {
        this._id_endereco = objeto.id_endereco
        this._bairro_distrito = objeto.bairro_distrito
        this._cep = objeto.cep
        this._complemento = objeto.complemento
        this._logradouro = objeto.logradouro
        this._municipio = objeto.municipio
        this._numero = objeto.numero
        this._uf = objeto.uf
    }

    get id_endereco(){ return this._id_endereco }

    get uf() { return this._uf }

    get bairro_distrito() { return this._bairro_distrito }

    get cep() { return this._cep }

    get numero() { return this._numero }

    get complemento() { return this._complemento }

    get logradouro() { return this._logradouro }

    get municipio() { return this._municipio }

    set id_endereco(newValue){ this._id_endereco = newValue }

    set municipio(newValue) { this._municipio = newValue }

    set uf(newValue) { this._uf = newValue }

    set bairro_distrito(newValue) { this._bairro_distrito = newValue }

    set cep(newValue) { this._cep = newValue }

    set numero(newValue) { this._numero = newValue }

    set complemento(newValue) { this._complemento = newValue }

    set logradouro(newValue) { this._logradouro = newValue }

} 