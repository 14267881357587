import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DownloadPdfService {

    private DOWNLOAD_PDF_URL: string = '/api/resultado-pregao-pdf'

    constructor(private http: HttpClient) { }

    public getPdfFile(pregao: any) {
        return this.http.get(`${this.DOWNLOAD_PDF_URL}/${pregao.id_pregao}`,{responseType: 'arraybuffer'}
           ).subscribe(response => this.downLoadFile(response, "application/pdf",pregao));
    }

    downLoadFile(data: any, type: string, pregao: any) {

        let blob = new Blob([data], { type: type }),
        anchor = document.createElement('a');
        anchor.download = `Resultado Pregão ${pregao.apelido}.pdf`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['pdf', anchor.download, anchor.href].join(':');
        anchor.click();

    }
}
