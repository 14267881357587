import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private ALL_DOCUMENTS_URL: string = '/api/documents'

    private DOCUMENT_URL: string = '/api/document'

    private DOCUMENT_SEARCH: string = '/api/document-search'

    private RELATORES_URL: string = '/api/relatores'

    private UPLOAD_DOCUMENT_DOCCANO_URL: string = '/api/upload-document-doccano'

    private CONFIG_DOCCANO_URL: string = '/api/config-doccano'

    constructor(private http: HttpClient) { }

    public getDoccanoConfig() {
        return this.http.get(this.CONFIG_DOCCANO_URL)
    }

    public getDocuments() {
        return this.http.get(this.ALL_DOCUMENTS_URL)
    }

    public getDocumentOutput(uuid: string, bucket_name: string) {
        return this.http.get(`${this.DOCUMENT_URL}/${uuid}/${bucket_name}`)
    }

    public searchInDocuments(searchArgument:string) {
        return this.http.get(`${this.DOCUMENT_SEARCH}?searchArgument=${searchArgument}`)
    }

    public getRelatores() {
        return this.http.get(`${this.RELATORES_URL}`)
    }

    public uploadDocumentDoccano(uuid, bucket_name) {
        return this.http.get(`${this.UPLOAD_DOCUMENT_DOCCANO_URL}/${uuid}/${bucket_name}`)
    }

}
