import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { DestinatarioService } from '../services/destinatario.service';
import { Router } from '@angular/router';
import { MensagemService } from '../services/mensagem.service';
import { MessageService } from 'primeng/api';


@Component({
    selector: 'app-usuarios-destino',
    templateUrl: './usuarios-destino.component.html',
    styleUrls: ['./usuarios-destino.component.css']
})
export class UsuariosDestinoComponent implements OnInit {

    destinatarios: any;
    loading: Boolean = false;
    apenasAcompanhando: Boolean = false;
    search = {
        displayName: '',
        username: '',
    };

    constructor(private mensagemService: MensagemService,
        private breadcrumbService: BreadcrumbService,
        private destinatarioService: DestinatarioService,
        private messageService: MessageService,
        private router: Router) { }

    getDestinatarios(): void {
        this.loading = true;
        this.destinatarioService.getDestinatarios(this.apenasAcompanhando).subscribe(resp => {
            this.destinatarios = resp;
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    handleChangeSearchAcompanhamento(event) {
        this.getDestinatarios();
    }

    handleChange(event, user) {
        let message;
        this.destinatarioService
            .updateUser(user)
            .subscribe(resp => {
                this.getDestinatarios();
                message = resp;
                this.showToast('success', message.message);
            }, err => {
                this.showToast('error', err.statusText);
        });
    }

    showToast(severity, message) {
        this.messageService.add({ key: 'toast-usuarios-destino', severity, summary: message });
    }

    click(event) {
        if (event.button === 'delete') {
            this.deletarUsuario(event.selection.id_user);
        }
    }

    deletarUsuario(id_user) {
        let message;
        this.loading = true;
        this.destinatarioService.deleteDestUser(id_user).subscribe(resp => {
            this.loading = false;
            this.getDestinatarios();
            message = resp;
            this.showToast('success', message.message);
        }, err => {
            this.loading = false;
        });

    }

    ngOnInit(): void {
        this.getDestinatarios();
    }

}
