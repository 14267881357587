import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LicitacaoService {

    private ANEXO_PREGAO_URL = '/api/coleta-anexos';
    private PESQUISA_LICITACAO_URL = '/api/pesquisa-anexos';
    private PROCESSO_ANEXO_URL = '/api/processo/anexo/pregao';

    constructor(private http: HttpClient) { }

    public getAnexosLicitacao(idPregao: number) {
        return this.http.get(`${this.ANEXO_PREGAO_URL}?idPregao=${idPregao}`);
    }

    public getAnexos(codigoUASG: number = 0, numeroLicitacao: number = 0, fornecedor: string, conteudo: string) {
        return this.http.get(`${this.PESQUISA_LICITACAO_URL}?nroLicitacao=${numeroLicitacao}&codigoUASG=${codigoUASG}&fornecedor=${fornecedor}&conteudo=${conteudo}`);
    }

    public getProcessoAnexo(uuid) {
        return this.http.get(`${this.PROCESSO_ANEXO_URL}/${uuid}`);
    }
}
