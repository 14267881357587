import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MensagemService {
    private PESQUISA_MENSAGEM_URL: string = '/api/pesquisa-mensagem'
    private MENSAGEM_DELETE_URL: string = '/api/delete-mensagem'

    constructor(private http: HttpClient) { }

    public getMensagens(codigoUASG=0, numeroLicitacao=0, dataInicio='', dataFim='', nomeFornecedor='', mensagem='') {
        return this.http.get(`${this.PESQUISA_MENSAGEM_URL}?numero_licitacao=${numeroLicitacao}&codigo_uasg=${codigoUASG}&data_inicio=${dataInicio}&data_fim=${dataFim}&nome_fornecedor=${nomeFornecedor}&mensagem=${mensagem}`)
    }

    public deleteMensagens(id_pregao) {
        return this.http.get(`${this.MENSAGEM_DELETE_URL}/${id_pregao}`)
    }
}
