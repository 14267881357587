import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DownloadXlsService {

    private DOWNLOAD_XLS_URL: string = '/api/download-pregao-xlsx'

    constructor(private http: HttpClient) { }

    public getXlsFile(pregao: any) {
        return this.http.get(`${this.DOWNLOAD_XLS_URL}/${pregao.id_pregao}/2`,{responseType: 'arraybuffer'} 
           ).subscribe(response => this.downLoadFile(response, "application/ms-excel",pregao));
    }

    downLoadFile(data: any, type: string, pregao: any) {

        let blob = new Blob([data], { type: type }),
        anchor = document.createElement('a');
        anchor.download = `Resultado Pregão ${pregao.apelido}.xlsx`;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['ms-excel', anchor.download, anchor.href].join(':');
        anchor.click();
        
    }
}
