import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LanceService {

    private PESQUISA_LANCE_URL = '/api/lances';
    private PESQUISA_FORNECEDORES_URL = '/api/fornecedores-pregao';
    private PESQUISA_ITENS_URL = '/api/itens-pregao';
    private PESQUISA_GRUPOS_URL = '/api/grupos-pregao';
    private BUSCA_LANCES_URL = '/api/coleta-lances';

    constructor(private http: HttpClient) { }

    public getLances(id_pregao, numeroItem= 0, numeroGrupo= 0, nomeFornecedor= '', dataInicio, dataFim) {
        return this.http.get(`${this.PESQUISA_LANCE_URL}?id_pregao=${id_pregao}&numero_item=${numeroItem}&numero_grupo=${numeroGrupo}&nome_fornecedor=${nomeFornecedor}&data_inicio=${dataInicio}&data_fim=${dataFim}`);
    }

    public getFornecedoresPregao(numeroLicitacao, numeroItem= 0, numeroGrupo= 0, dataInicio= '', dataFim= '') {
        return this.http.get(`${this.PESQUISA_FORNECEDORES_URL}?id_pregao=${numeroLicitacao}&numero_item=${numeroItem}&numero_grupo=${numeroGrupo}&data_inicio=${dataInicio}&data_fim=${dataFim}`);
    }

    public getItens<Array>(numeroLicitacao) {
        return this.http.get(`${this.PESQUISA_ITENS_URL}?id_pregao=${numeroLicitacao}`);
    }

    public getGrupos<Array>(numeroLicitacao) {
        return this.http.get(`${this.PESQUISA_GRUPOS_URL}?id_pregao=${numeroLicitacao}`);
    }

    public buscarLances(idPregao: number) {
        return this.http.get(`${this.BUSCA_LANCES_URL}?idPregao=${idPregao}`);
    }
}
