import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService, DatatableComponent, ShowColumn } from '@nuvem/primeng-components';
import { MessageService } from 'primeng';
import { FornecedorService } from '../services/fornecedor.service';
import { PessoaJuridica } from './pessoa_juridica.model';

@Component({
    selector: 'app-fornecedor-view',
    templateUrl: './fornecedor-view.component.html',
    styleUrls: ['./fornecedor-view.component.css']
})
export class FornecedorViewComponent implements OnInit, AfterViewInit {
    @Input() fornecedor: Object;
    pessoa_juridica: PessoaJuridica;
    error: Object;
    loading_portal: boolean = true;

    @ViewChildren('notas_fiscais_table') notas_fiscais_search: QueryList<DatatableComponent>;
    private notas_fiscais_table: DatatableComponent;

    constructor(private route: ActivatedRoute,
        private fornecedorService: FornecedorService,
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        private ref: ChangeDetectorRef) { }


    ngOnInit(): void {
        let params = this.route.snapshot.paramMap.get('id').split('/');
        let id = params[0];

        this.fornecedorService.getFornecedorById(id).subscribe(resp => {
            this.fornecedor = resp;
        })

        this.fornecedorService.getEmpresaPortalTransp(id).subscribe(resp => {
            this.error = resp['error']
            if (!this.error) {
                this.pessoa_juridica = new PessoaJuridica(resp);
            }
            this.loading_portal = false;
        },
            err => {
                this.loading_portal = false
            })

    }

    ngAfterViewInit(): void {
        this.notas_fiscais_search.changes.subscribe((notas: QueryList <DatatableComponent>) => 
        {
            this.notas_fiscais_table = notas.first;
            if (this.notas_fiscais_table !== undefined){
                this.notas_fiscais_table.visibleColumns = this.defaultColumns_socio()
                this.ref.detectChanges()
            }
        });
    }

    defaultColumns_socio(): object {
        return {
            "chave_nota_fiscal": "table-cell",
            "orgao_superior_destinatario": "table-cell",
            "orgao_destinatario": "table-cell",
            "cnpj_orgao_destinatario": "none",
            "nome_fornecedor": "none",
            "cnpj_fornecedor": "none",
            "municipio_fornecedor": "none",
            "uf_fornecedor": "none",
            "valor_nota_fiscal": "table-cell",
            "data_emissao": "table-cell",
            "data_tipo_evento_mais_recente": "table-cell",
            "tipo_evento_mais_recente": "table-cell",
            "numero": "none",
            "serie": "none"
        }
        
    }

}
