import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SistemaService {

    private URL_SISTEMAS: string = '/api/get-sistemas'

    constructor(private http: HttpClient) { }

    public getSistemas() {
        return this.http.get(`${this.URL_SISTEMAS}`)
    }
}
