import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RecursoService {

    private PESQUISA_RECURSO_URL = '/api/pesquisa-recurso';
    private BUSCA_RECURSO_URL = '/api/coleta-recursos';

    constructor(private http: HttpClient) { }

    public getRecursos(nomeFornecedorAlvo: string, nomeFornecedor: string, codigoPrg: number = 0, conteudo: string, campoPesquisa: string) {
        return this.http.get(`${this.PESQUISA_RECURSO_URL}?nomeFornecedorAlvo=${nomeFornecedorAlvo}&nomeFornecedor=${nomeFornecedor}&codigoPrg=${codigoPrg}&conteudo=${conteudo}&campoPesquisa=${campoPesquisa}`);
    }

    public collectRecursos(idPregao: number) {
        return this.http.get(`${this.BUSCA_RECURSO_URL}?idPregao=${idPregao}`);
    }
}
