import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { RecursoService } from '../services/recurso.service';
import { MessageService } from 'primeng/api';
import {interval, from, Subscription} from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { PregaoService } from '../services/pregao.service';

@Component({
    selector: 'app-pesquisa-recurso',
    templateUrl: './pesquisa-recurso.component.html',
    styleUrls: ['./pesquisa-recurso.component.scss']
})
export class PesquisaRecursoComponent implements OnInit, OnDestroy {

    @Input() pregao;
    fornecedorContraRazao = '';
    fornecedorRecurso = '';
    conteudo = '';
    selectedField = 'intencao_recurso';
    campoPesquisa = '';
    listaProcessos: any;
    fields = [
        { label: 'Intenção de Recurso', value: 'intencao_recurso'},
        { label: 'Recurso', value: 'recurso'},
        { label: 'Contra-Razão', value: 'contra_razao'}
    ];

    recursos: any[] = [];
    uuidProcesso;
    progressSubscription: Subscription;
    loading=false;

    constructor(private breadcrumbService: BreadcrumbService,
                private messageService: MessageService,
                private recursoService: RecursoService,
                private pregaoService: PregaoService) { }

    getRecursos() {
        this.recursoService
            .getRecursos(this.fornecedorContraRazao, this.fornecedorRecurso, this.pregao.codigo_prg, this.conteudo, this.selectedField)
            .subscribe((recursos: any[]) => {
                this.recursos = recursos;
                const campo = this.selectedField;
                this.campoPesquisa = campo;
            });
    }

    updateProcesso() {
        this.progressSubscription = interval(2000).pipe(
            switchMap(() => from(this.pregaoService.getProcessoPregao(this.uuidProcesso))),
            takeWhile((resp: any[]) => {
                let atualizar = true;
                if (resp.length > 0) {
                    for (const processo of resp) {
                        if (processo.data_fim !== null) {
                            this.listaProcessos = resp;
                            atualizar = false;
                            if (processo.status === 'SEM_RECURSOS') {
                                this.showToast('Não foram encontrados recursos no ComprasNet', 'info', 'Aviso');
                            } else {
                                this.getRecursos();
                            }
                        }
                    }
                }

                return atualizar;
            }),
        ).subscribe((resp: any[]) => {
            this.listaProcessos = resp;
        });
    }

    collectRecursosLicitacao() {
        this.recursoService
            .collectRecursos(this.pregao.id_pregao)
            .subscribe(resp => {
                this.showToast(resp['message'], resp['severity'], resp['summary']);
                if (resp['uuid'] !== undefined) {
                    this.uuidProcesso = resp['uuid'];
                    this.updateProcesso();
                }
            });
    }

    onChangeDropdown(event) {
        this.getRecursos();
    }

    showToast(detail, severity, summary) {
        this.messageService.clear();
        this.messageService.add({ key: 'toast-recurso-pregao', severity: severity, summary: summary, detail: detail });
    }

    getCampoSelectionado() {
        for (const obj of this.fields) {
            if (this.selectedField === obj.value) {
                return obj.label;
            }
        }
    }

    ngOnInit() {
        this.getRecursos();
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    }
}
