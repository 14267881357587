import { Endereco } from './endereco.model';
import { PessoaJuridicaSocio } from './pessoa_juridica_socio.model';
import { PessoaJuridicaAcordos } from './pessoa_juridica_acordos.model';
import { PessoaJuridicaDados } from './pessoa_juridica_dados.model';
import { PessoaJuridicaValores } from './pessoa_juridica_valores.model';
import { NotaFiscal } from './nota_fiscal.model';

export class PessoaJuridica {
    private _endereco: Endereco;
    private _quadro_societario: PessoaJuridicaSocio[] = [];
    private _acordos: PessoaJuridicaAcordos;
    private _dados_empresa: PessoaJuridicaDados;
    private _valores: PessoaJuridicaValores;
    private _notas_fiscais : NotaFiscal[] = [];
    constructor(objeto) {

        if (objeto.endereco) {
            this._endereco = new Endereco(objeto.endereco)
        }
        if (objeto.acordos) {
            this._acordos = new PessoaJuridicaAcordos(objeto.acordos)
        }
        if (objeto.dados_empresa) {
            this._dados_empresa = new PessoaJuridicaDados(objeto.dados_empresa)
        }
        if (objeto.valores) {
            this._valores = new PessoaJuridicaValores(objeto.valores)
        }
        if (objeto.quadro_societario) {
            for (var socio of objeto.quadro_societario) {
                this._quadro_societario.push(new PessoaJuridicaSocio(socio));
            }
        }
        if (objeto.notas_fiscais) {
            for (var nota of objeto.notas_fiscais) {
                this._notas_fiscais.push(new NotaFiscal(nota));
            }
        }

    }


    get endereco() { return this._endereco }

    get quadro_societario() { return this._quadro_societario }

    get notas_fiscais() { return this._notas_fiscais }

    get acordos() { return this._acordos }

    get dados_empresa() { return this._dados_empresa }

    get valores() { return this._valores }

    set endereco(newValue) { this._endereco = newValue }

    set quadro_societario(newValue) { this._quadro_societario = newValue }

    set notas_fiscais(newValue) { this._notas_fiscais = newValue }

    set acordos(newValue) { this._acordos = newValue }

    set dados_empresa(newValue) { this._dados_empresa = newValue }

    set valores(newValue) { this._valores = newValue }

} 