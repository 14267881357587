import { trigger, state, style, transition,
    animate, group, query, stagger, keyframes
} from '@angular/animations';

export const SlideInOutVertical = [
    trigger(
        'slideInOutVertical',
        [
            transition(
                ':enter',
                [
                    style({ height: 0, opacity: 0 }),
                    animate('0.25s ease-out',
                        style({ height: 76, opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ height: 76, opacity: 1 }),
                    animate('0.25s ease-in',
                        style({ height: 0, opacity: 0 }))
                ]
            )
        ]
    )
]

export const SlideInOutHorizontal = [
    trigger(
        'slideInOutHorizontal',
        [
            transition(
                ':enter',
                [
                    style({ maxWidth: 0, opacity: 0 }),
                    animate('0.25s ease-out', style({ maxWidth: 250, opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ maxWidth: 250, opacity: 1 }),
                    animate('0.17s ease-in', style({ maxWidth: 0, opacity: 0, maxHeight: '100px' }))
                ]
            )
        ]
    )
]