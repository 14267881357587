import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '@nuvem/primeng-components';
import { DestinatarioService } from '../services/destinatario.service';
import { Router } from '@angular/router';
import { MensagemService } from '../services/mensagem.service';
import { MessageService } from 'primeng/api';
import {AutoCompleteModule} from 'primeng/autocomplete';

@Component({
    selector: 'app-cadastro-destinatario',
    templateUrl: './cadastro-destinatario.component.html',
    styleUrls: ['./cadastro-destinatario.component.css']
})
export class CadastroDestinatarioComponent implements OnInit {
    user: any;
    results: any;
    TOAST_WAIT_TIME: number = 800
    constructor(
        private mensagemService: MensagemService,
        private breadcrumbService: BreadcrumbService,
        private destinatarioService: DestinatarioService,
        private messageService: MessageService,
        private router: Router) { }
    

    searchUsername(event){
        this.destinatarioService.searchUsername(event.query).subscribe(data => {
            this.results = data;
        });
        console.log(this.results)
    }

    addDestUser() {
        console.log(this.user)
        this.destinatarioService
            .postDestUser(this.user.sAMAccountName[0],this.user.displayName[0])
            .subscribe(resp => {
                this.showSuccess(resp)
                setTimeout(() => {
                    this.router.navigate(['/usuarios-destino'])
                }, this.TOAST_WAIT_TIME);
            })
    }

    showSuccess(detail) {
        this.messageService.clear()
        this.messageService.add({key: 'toast-adicionar-usuario', severity:'success', summary: 'Sucesso!' });
    }

    ngOnInit(): void {
    }

}
