import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentService } from '../services/document.service'
import { BreadcrumbService, DatatableComponent } from '@nuvem/primeng-components';
import { Router } from '@angular/router'

@Component({
    selector: 'app-document-table-view',
    templateUrl: './document-table-view.component.html',
    styleUrls: ['./document-table-view.component.css']
})
export class DocumentTableViewComponent implements OnInit {

    relatores: any[]
    relator: string
    dataInicio
    dataFim
    @ViewChild(DatatableComponent, {static: true})
    table: DatatableComponent;

    constructor(private documentService: DocumentService, private breadcrumbService: BreadcrumbService, private router: Router) { }

    click(event) {
        if (event.selection) {
            this.router.navigate(['/documento', event.selection.file_uuid + '/' + event.selection.bucket_name])
        }
    }

    limparFiltros() {
        this.relator = ''
        this.dataInicio = null
        this.dataFim = null

        this.pesquisar()
    }

    formatDateTime(date) {
        return date !== undefined && date !== null ? date.toLocaleString() : ''
    }

    pesquisar() {
        this.table.filterParams = {
          relator: this.relator || '',
          dataInicio: this.formatDateTime(this.dataInicio) || '',
          dataFim: this.formatDateTime(this.dataFim) || ''
        };
        this.table.filter();
    }

    getRelatores() {
        this.documentService.getRelatores().subscribe((resp: any[]) => {
            this.relatores = resp.map(relator => {
                let dic = { label: relator.relator, value: relator.relator }
                return dic
            })
        })
    }

    ngOnInit() {
        this.breadcrumbService.setItems([{ label: 'Documentos' }]);
        this.getRelatores()
    }

    ngOnDestroy() {
        this.breadcrumbService.reset();
    }

}
