export class PessoaJuridicaDados {

    private _id_endereco: string;
    private _id_fornecedor: string;
    private _id_pessoa_juridica: string;
    private _id_portal_transparencia: string;
    private _cnae: string;
    private _cnpj: string;
    private _data_abertura: string;
    private _endereco_eletronico: string;
    private _last_fetch: string;
    private _natureza_juridica: string;
    private _nome_empresa: string;
    private _nome_fantasia: string;
    private _numero_incricao: string;
    private _telefone: string;
    private _tipo_empresa: string;

    constructor(objeto) {
        this._id_endereco = objeto.id_endereco
        this._id_fornecedor = objeto.id_fornecedor
        this._id_pessoa_juridica = objeto.id_pessoa_juridica
        this._id_portal_transparencia = objeto.id_portal_transparencia
        this._cnae = objeto.cnae
        this._cnpj = objeto.cnpj
        this._data_abertura = objeto.data_abertura
        this._endereco_eletronico = objeto.endereco_eletronico
        this._last_fetch = objeto.last_fetch
        this._natureza_juridica = objeto.natureza_juridica
        this._nome_empresa = objeto.nome_empresa
        this._nome_fantasia = objeto.nome_fantasia
        this._numero_incricao = objeto.numero_incricao
        this._telefone = objeto.telefone
        this._tipo_empresa = objeto.tipo_empresa
    }
    
    get id_endereco(){ return this._id_endereco }

    get id_fornecedor(){ return this._id_fornecedor }

    get id_pessoa_juridica(){ return this._id_pessoa_juridica }

    get id_portal_transparencia(){ return this._id_portal_transparencia }

    get cnae(){ return this._cnae }

    get cnpj(){ return this._cnpj }

    get data_abertura(){ return this._data_abertura }

    get endereco_eletronico(){ return this._endereco_eletronico }

    get last_fetch(){ return this._last_fetch }

    get natureza_juridica(){ return this._natureza_juridica }

    get nome_empresa(){ return this._nome_empresa }

    get nome_fantasia(){ return this._nome_fantasia }

    get numero_incricao(){ return this._numero_incricao }

    get telefone(){ return this._telefone }

    get tipo_empresa(){ return this._tipo_empresa }

    set id_endereco(newValue){ this._id_endereco = newValue }

    set id_fornecedor(newValue){ this._id_fornecedor = newValue }

    set id_pessoa_juridica(newValue){ this._id_pessoa_juridica = newValue }

    set id_portal_transparencia(newValue){ this._id_portal_transparencia = newValue }

    set cnae(newValue){ this._cnae = newValue }

    set cnpj(newValue){ this._cnpj = newValue }

    set data_abertura(newValue){ this._data_abertura = newValue }

    set endereco_eletronico(newValue){ this._endereco_eletronico = newValue }

    set last_fetch(newValue){ this._last_fetch = newValue }

    set natureza_juridica(newValue){ this._natureza_juridica = newValue }

    set nome_empresa(newValue){ this._nome_empresa = newValue }

    set nome_fantasia(newValue){ this._nome_fantasia = newValue }

    set numero_incricao(newValue){ this._numero_incricao = newValue }

    set telefone(newValue){ this._telefone = newValue }

    set tipo_empresa(newValue){ this._tipo_empresa = newValue }

} 